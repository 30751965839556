import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as T } from 'react-intl';

import { GoBack, Image, Layout, Link, SEO } from 'components';
import { routes } from 'utils/routes';

import content from './what-sets-us-apart.yml';

const imgCheck = <Image url='icons/icon_check.svg' alt='It is a match' />;
const imgCross = <Image url='icons/icon_cross.svg' alt='It is different' />;

const WhatSetsUsApartPage = ({ pageContext: { locale }, location }) => (
  <Layout locale={locale} className='apart-wrapper'>
    <SEO
      title={content.title}
      description={content.description}
      lang={locale}
      keywords={content.keywords}
      location={location}
      image={content.socialImage}
    />

    <div className='title-wrapper mt-50 mb-50'>
      <GoBack />
      <h3 className='section-title'>What sets Straight Teeth Direct&trade; apart</h3>
    </div>

    <div className='table-wrapper'>
      <table>
        <thead>
          <tr>
            <th className='col1'></th>
            <th className='col2'>Straight Teeth Direct</th>
            <th className='col3'>In clinic</th>
            <th className='col4'>Other online companies</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><p>Cost</p></td>
            <td><p><T id="apart.cost.std">{txt => txt}</T> all inclusive<br/>(Including retainers)</p></td>
            <td><p>Varies <T id="apart.cost.clinic">{txt => txt}</T><br/>with possible additional costs</p></td>
            <td><p>Average price <T id="apart.cost.online">{txt => txt}</T><br/>with possible additional costs</p></td>
          </tr>
          <tr className='highlight'><td colSpan='4'><p>Guarantees</p></td></tr>
          <tr>
            <td><p>Evaluation & Impressions</p></td>
            <td><div className='td-wrapper'>{imgCheck}</div></td>
            <td><div className='td-wrapper'>{imgCheck}</div></td>
            <td><div className='td-wrapper'>{imgCross}</div></td>
          </tr>
          <tr>
            <td><p>Replacements</p></td>
            <td><div className='td-wrapper'>{imgCheck}</div></td>
            <td><div className='td-wrapper'>{imgCross}<p className='info'>At extra cost</p></div></td>
            <td><div className='td-wrapper'>{imgCross}<p className='info'>At extra cost</p></div></td>
          </tr>
          <tr>
            <td><p>Refinements</p></td>
            <td><div className='td-wrapper'>{imgCheck}</div></td>
            <td><div className='td-wrapper'>{imgCross}<p className='info'>At extra cost</p></div></td>
            <td><div className='td-wrapper'>{imgCross}<p className='info'>At extra cost</p></div></td>
          </tr>
          <tr>
            <td><p>Aftercare</p></td>
            <td><div className='td-wrapper'>{imgCheck}<p className='info'>Affordable Maintainer Plan</p></div></td>
            <td><div className='td-wrapper'>{imgCheck}<p className='info'>Expensive solutions</p></div></td>
            <td><div className='td-wrapper'>{imgCross}</div></td>
          </tr>
          <tr className='highlight'><td colSpan='4'><p>Production</p></td></tr>
          <tr>
            <td><p>Inhouse</p></td>
            <td><div className='td-wrapper'>{imgCheck}</div></td>
            <td><div className='td-wrapper'>{imgCross}</div></td>
            <td><div className='td-wrapper'>{imgCross}</div></td>
          </tr>
          <tr>
            <td><p>In stages</p></td>
            <td><div className='td-wrapper'>{imgCheck}<p className='info'>According to tracked progress</p></div></td>
            <td><div className='td-wrapper'>{imgCheck}<p className='info'>Based on clinic visits</p></div></td>
            <td><div className='td-wrapper'>{imgCross}<p className='info'>Sent all in one go at the start</p></div></td>
          </tr>
          <tr>
            <td><p>Soft and hard extra comfortable aligners</p></td>
            <td><div className='td-wrapper'>{imgCheck}<p className='info'>Soft and hard extra comfortable aligners</p></div></td>
            <td><div className='td-wrapper'>{imgCross}<p className='info'>Visible attachments bondend to teeth & enamel filing</p></div></td>
            <td><div className='td-wrapper'>{imgCross}</div></td>
          </tr>
          <tr className='highlight'><td colSpan='4'><p>Shipping</p></td></tr>
          <tr>
            <td><p>Worldwide free shipping</p></td>
            <td><div className='td-wrapper'>{imgCheck}<p className='info'>You can also change your address for maximum flexibility</p></div></td>
            <td><div className='td-wrapper'>{imgCross}<p className='info'>Fixed local appointments</p></div></td>
            <td><div className='td-wrapper'>{imgCross}<p className='info'>If available may be subject to extra cost</p></div></td>
          </tr>
        </tbody>
      </table>
    </div>

    <div className='flex-center mt-50 mb-90'>
      <Link to={routes.compare.path} {...routes.compare} className='button ghost mr-10'>Compare more</Link>
      <Link to={routes.getStarted.path} {...routes.getStarted} className='button ml-10'>Get started</Link>
    </div>
  </Layout>
);

WhatSetsUsApartPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
};

export default WhatSetsUsApartPage;
